(function() {
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    function getURLParameter(name) {
        const param = new URLSearchParams(window.location.search).get(name);
        return param ? decodeURIComponent(param) : '';
    }
    const currentURL = window.location.href;
    console.log('Current URL:', currentURL);

    const intercomId = getCookie('intercom-id-ijlfonvh') || '';
    const fbclid = getCookie('fbclid') || '';
    const gclid = getCookie('gclid') || '';
    const utm_medium = getCookie('utm_medium') || '';
    const utm_campaign = getCookie('utm_campaign') || '';
    const utm_content = getCookie('utm_content') || '';
    const utm_source = getCookie('utm_source') || '';
    const utm_term = decodeURI(decodeURI(getCookie('utm_term') || ''));
    const istest = getURLParameter('istest')

    console.log('Intercom ID:', intercomId);
    console.log('fbclid:', fbclid);
    console.log('gclid:', gclid);
    console.log('utm_medium:', utm_medium);
    console.log('utm_campaign:', utm_campaign);
    console.log('utm_content:', utm_content);
    console.log('utm_source:', utm_source);
    console.log('utm_term:', utm_term);
    console.log('istest:', utm_term);

    function appendHiddenInput(form, name, value) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
        console.log('Added hidden input:', name, value);
    }

    function addHiddenInputsToForm(form) {
        if (form) {
            appendHiddenInput(form, 'fullURL', currentURL);
            appendHiddenInput(form, 'intercomId', intercomId);
            appendHiddenInput(form, 'fbclid', fbclid);
            appendHiddenInput(form, 'gclid', gclid);
            appendHiddenInput(form, 'utm_medium', utm_medium);
            appendHiddenInput(form, 'utm_campaign', utm_campaign);
            appendHiddenInput(form, 'utm_content', utm_content);
            appendHiddenInput(form, 'utm_source', utm_source);
            appendHiddenInput(form, 'utm_term', utm_term);
            appendHiddenInput(form, 'istest', istest);

        } else {
            console.error('Form not found');
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        console.log('DOM fully loaded and parsed');
        addHiddenInputsToForm(document.querySelector('form'));
    });

    window.addEventListener('heyflow-init', (event) => {
        console.log('Heyflow initialized', event.detail.flowID);

        const shadowHost = document.querySelector(`[flow-id="${event.detail.flowID}"]`);
        if (shadowHost && shadowHost.shadowRoot) {
            const shadowRoot = shadowHost.shadowRoot;
            const form = shadowRoot.querySelector('form');
            if (form) {
                addHiddenInputsToForm(form);
            } else {
                console.error('Form not found in shadow root');
            }
        } else {
            console.error('Shadow root not found');
        }
    });
})();
